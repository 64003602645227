import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { PickList } from "primereact/picklist";
import classnames from "classnames/dedupe";
import { Input } from "reactstrap";

import SuperscriptDisplay from "components/superscript/superscript-display";
import { Icon } from "..";

import "./style.scss";

interface IDualListBoxProps {
    options: any[] | undefined | null;
    selected: any[] | undefined;
    canFilter?: boolean;
    filterPlaceholder?: string;
    disabled?: boolean;
    superscript?: boolean;
    className?: string;
    onChange: (e) => void;
}

export const DualListBox = ({
    filterPlaceholder = "",
    canFilter = true,
    onChange,
    options,
    selected,
    disabled = false,
    superscript = false,
    className = "",
}: IDualListBoxProps) => {
    const [filterInputSource, setFilterInputSource] = useState<string>("");
    const [filterInputTarget, setFilterInputTarget] = useState<string>("");

    const getOptions = () => {
        if (superscript) {
            options = options?.map((o, i) => ({
                label: () => <SuperscriptDisplay key={`opt-${i}`} value={o.label} />,
                stringValue: o.label.replace(/[↑↓→←]/gi, ""),
                value: o.value,
            }));
        }

        return options?.filter((o) => {
            return !selected?.includes(o.value) && (o.stringValue || o.label)?.toLowerCase().includes(filterInputSource.toLowerCase());
        });
    };

    const target =
        options?.filter(
            (o) => selected?.includes(o.value) && (o.stringValue || o.label)?.toLowerCase().includes(filterInputTarget.toLowerCase())
        ) || [];

    return (
        <PickList
            source={getOptions() || []}
            className={classnames(className, { disabled })}
            target={target}
            onChange={onChange}
            showSourceControls={false}
            showTargetControls={false}
            itemTemplate={(item) => <ItemTemplate item={item} />}
            targetHeader={
                <Header filterInput={filterInputTarget} setFilterInput={setFilterInputTarget} canFilter={canFilter} text="Selected" />
            }
            sourceHeader={
                <Header filterInput={filterInputSource} setFilterInput={setFilterInputSource} canFilter={canFilter} text="Available" />
            }
        />
    );
};

const ItemTemplate = ({ item }) => {
    return <div>{typeof item.label == "function" ? item.label() : item.label}</div>;
};

const Header = ({ filterInput, setFilterInput, canFilter, text }) => {
    const { t } = useTranslation();

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>();

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterInput(value);
    };

    const handleOpenFilter = () => {
        setIsFilterOpen((prev) => !prev);
    };

    return (
        <div className="picklist-header">
            <span> {t(text)}</span>
            {canFilter && (
                <div className={classnames("filter", { open: isFilterOpen })}>
                    <Icon name="filter" onClick={handleOpenFilter} />
                    <Input value={filterInput} onChange={handleChange} />
                </div>
            )}
        </div>
    );
};
