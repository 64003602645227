import React, { CSSProperties } from "react";

import { InputSwitch as PrimeInputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import classnames from "classnames/dedupe";

import hasPermission from "../../utils/hasPermissions";
import LabelError from "../label-error/label-error";
import addToast from "../../utils/addToast";

import "./input-switch.style.scss";

interface IInputSwitch {
    onChange?: any;
    forLabel?: string | undefined;
    label?: string;
    name?: any;
    className?: string;
    containerClassName?: string;
    noLabel?: boolean;
    disabled?: boolean;
    checked?: boolean;
    errors?: any;
    id?: string | number;
    style?: CSSProperties;
    labelFirst?: boolean;
    permCode?: string;
    labelSm?: number;
    colSm?: number;
}

export const InputSwitch = (props: IInputSwitch) => {
    const { t } = useTranslation();

    const {
        onChange,
        forLabel = "",
        label,
        name,
        className = "",
        containerClassName = "",
        noLabel = false,
        disabled = false,
        checked = false,
        errors,
        style,
        labelFirst = true,
        permCode,
        labelSm = 3,
        colSm = 9,
    } = props;

    const handleChange = (e) => {
        if (permCode && !hasPermission(permCode)) return addToast({ title: "No permissions", color: "danger" });
        onChange(e);
    };

    return (
        <Row
            className={classnames(
                {
                    [containerClassName]: !!containerClassName,
                },
                "gt-input-switch-container"
            )}
        >
            {labelFirst && !noLabel && (
                <Col sm={labelSm}>
                    <label className="input-switch-label" htmlFor="input-switch">
                        {t(label || "")}:
                    </label>
                </Col>
            )}
            <Col sm={colSm} className="input-switch-container">
                <PrimeInputSwitch
                    name={name}
                    onChange={handleChange}
                    checked={checked}
                    disabled={disabled}
                    id="input-switch"
                    className={classnames(
                        {
                            [className]: !!className,
                        },
                        "gt-input-switch"
                    )}
                    style={style}
                />
            </Col>
            {errors && <LabelError id={forLabel} error={t(errors)} />}
            {!labelFirst && !noLabel && (
                <Col sm={labelSm}>
                    <label className="input-switch-label" htmlFor="input-switch">
                        {t(label || "")}
                    </label>
                </Col>
            )}
        </Row>
    );
};
