import React, { Fragment } from "react";

import { Col, Label, Row } from "reactstrap";

import { SamplingPointSelect } from "pages/BasicSettings/SamplingSites/components/sampling-point-select";
import { SamplingSiteSelect } from "pages/BasicSettings/SamplingSites/components/sampling-site-select";
import { InputSwitch } from "components/input-switch/input-switch";
import { SelectWithOptions } from "./select-with-options";
import { GT } from "components";
import i18n from "i18n";

interface IFormFields {
    fields: IFormField[];
    formData: any;
    setFormData?: any;
    errors?: IErrors;
    onChange?: (e: any, f?: any) => void;
}

export const FormFields = (props: IFormFields) => {
    const { fields, formData, errors, setFormData, onChange } = props;

    const handleChange = (e, f) => {
        let { value, checked, name } = e.label ? { value: e.value, name: f.name, checked: undefined } : e.target || { value: e, name: f };
        if (value === undefined) {
            value = null;
        }
        setFormData && !name.includes(".")
            ? setFormData((prev) => ({ ...prev, [name]: checked || value }))
            : setFormData((prev) => {
                  const [_name, unit] = name.split(".");
                  return { ...prev, [_name]: { ...prev?.[_name], [unit]: value } };
              });
    };

    return (
        <>
            {fields.map((field, index) =>
                field?.display || field?.display === undefined
                    ? formFieldRenderer(field, formData, errors, index, onChange || handleChange)
                    : undefined
            )}
        </>
    );
};

const formFieldRenderer = (field: IFormField, formData, errors, index, onChange: (e: any, f?: any) => void) => {
    return {
        select: (
            <SelectWithOptions
                key={index}
                formData={formData}
                errors={errors}
                field={field}
                onChange={onChange}
                autofocus={field.autofocus || index == 0}
                {...field.props}
            />
        ),
        primeSelect: (
            <SelectWithOptions
                key={index}
                formData={formData}
                errors={errors}
                field={field}
                onChange={onChange}
                autofocus={field.autofocus || index == 0}
                {...field.props}
            />
        ),
        multiSelect: (
            <SelectWithOptions
                key={index}
                formData={formData}
                errors={errors}
                field={field}
                onChange={onChange}
                autofocus={field.autofocus || index == 0}
                {...field.props}
            />
        ),
        creatableSelect: (
            <SelectWithOptions
                key={index}
                formData={formData}
                errors={errors}
                field={field}
                onChange={onChange}
                autofocus={field.autofocus || index == 0}
                {...field.props}
            />
        ),
        multiCreatableSelect: (
            <SelectWithOptions
                key={index}
                formData={formData}
                errors={errors}
                field={field}
                onChange={onChange}
                autofocus={field.autofocus || index == 0}
                {...field.props}
            />
        ),
        input: (
            <GT.CustomInput
                key={index}
                labelText={field.label}
                name={field.name}
                value={field?.value || formData?.[field.name] || ""}
                handleChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                autofocus={field.autofocus || index == 0}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                {...field.props}
            />
        ),
        numeric: (
            <GT.CustomInput
                key={index}
                type="number"
                labelText={field.label}
                name={field.name}
                options={field.options}
                value={field?.value || formData?.[field.name] || ""}
                handleChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                autofocus={field.autofocus || index == 0}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                {...field.props}
            />
        ),
        checkbox: (
            <GT.CustomCheckbox
                key={index}
                labelText={field.label}
                name={field.name}
                options={field.options}
                checked={field?.value || formData?.[field.name]}
                handleChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                autofocus={field.autofocus || index == 0}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                {...field.props}
            />
        ),
        textarea: (
            <GT.CustomInput
                key={index}
                type="textarea"
                labelText={field.label}
                name={field.name}
                options={field.options}
                value={field?.value || formData?.[field.name] || ""}
                handleChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                autofocus={field.autofocus || index == 0}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                {...field.props}
            />
        ),
        date: (
            <GT.CustomDatePicker
                key={index}
                labelText={field.label}
                name={field.name}
                selectedDate={field?.value || formData?.[field.name]}
                handleChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                autofocus={field.autofocus || index == 0}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                {...field.props}
            />
        ),
        dateTime: (
            <GT.DateTimePicker
                key={index}
                label={field.label}
                name={field.name}
                value={field?.value || formData?.[field.name]}
                onChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                showTime
                {...field.props}
            />
        ),
        duration: (
            <Row key={index}>
                <Col sm={9}>
                    <GT.CustomInput
                        labelText={field.label}
                        type="number"
                        name={`${field.name}.time`}
                        handleChange={field.onChange || onChange}
                        value={field.value || formData?.[field.name]?.time || ""}
                        errors={field.errors || errors?.[field.name]}
                        className={field.className}
                        noLabel={!field.label}
                        disabled={field.disabled}
                        colSm={8}
                        labelSm={4}
                        min={0}
                        {...field.props}
                    />
                </Col>
                <Col sm={3}>
                    <GT.PrimeSelect
                        placeholder="Unit"
                        name={`${field.name}.unit`}
                        value={field.value || formData?.[field.name]?.unit}
                        onChange={field.onChange || onChange}
                        options={field.options}
                        disabled={field.disabled}
                        errors={field.errors || errors?.[field.name]}
                        className={field.className}
                        translateOptions
                        {...field.props}
                    />
                </Col>
            </Row>
        ),
        lookup: (
            <GT.LookupField
                key={index}
                errors={errors}
                handleChange={field.onChange || onChange}
                name={field.name}
                lookupName={field?.lookupName as string}
                label={field.label as string}
                value={field.value || formData?.[field.name]}
                selectProps={{ ...field.props }}
            />
        ),
        money: (
            <Row key={index}>
                <Col sm={9}>
                    <GT.CustomInput
                        labelText={field.label}
                        type="number"
                        name={`${field.name}.amount`}
                        handleChange={field.onChange || onChange}
                        value={field.value || formData?.[field.name]?.amount || ""}
                        errors={field.errors || errors?.[field.name]}
                        className={field.className}
                        disabled={field.disabled}
                        noLabel={!field.label}
                        colSm={8}
                        labelSm={4}
                        min={0}
                        {...field.props}
                    />
                </Col>
                <Col sm={3}>
                    <GT.PrimeSelect
                        placeholder="Currency"
                        name={`${field.name}.currency`}
                        value={field.value || formData?.[field.name]?.currency}
                        onChange={field.onChange || onChange}
                        options={field.options}
                        disabled={field.disabled}
                        errors={field.errors || errors?.[field.name]}
                        className={field.className}
                        showClear={false}
                        {...field.props}
                    />
                </Col>
            </Row>
        ),
        samplingPoint: (
            <SamplingPointSelect
                key={index}
                errors={field.errors || errors?.[field.name]}
                handleChange={field.onChange || onChange}
                value={field?.value || formData?.[field.name]}
                samplingSites={field.props?.samplingSites || []}
                disabled={field?.disabled}
                {...field.props}
            />
        ),
        samplingSite: (
            <SamplingSiteSelect
                key={index}
                value={field?.value || formData?.[field.name]}
                errors={field.errors || errors?.[field.name]}
                client={formData?.client}
                handleChange={field.onChange || onChange}
                url="crm/sampling-sites-formdata"
                {...field.props}
            />
        ),
        switch: (
            <InputSwitch
                key={index}
                label={field.label}
                name={field.name}
                options={field.options}
                checked={field?.value || formData?.[field.name]}
                onChange={field.onChange || onChange}
                disabled={field.disabled}
                required={field.required}
                autofocus={field.autofocus}
                errors={field.errors || errors?.[field.name]}
                className={field.className}
                noLabel={!field.label}
                {...field.props}
            />
        ),
        inputGroup: (
            <GT.PrimeInputGroup
                name={field.name}
                className={field.className}
                handleChange={field.onChange || onChange}
                labelText={field.label}
                required={field.required}
                key={index}
                value={field?.value || formData?.[field.name]}
                errors={field.errors || errors?.[field.name]}
                {...field.props}
            />
        ),
        label: (
            <Label key={index} className={field.className}>
                {i18n.t(field.label || field.name)}
            </Label>
        ),
        custom: <Fragment key={index}>{field?.customElement}</Fragment>,
    }[field.fieldType];
};
