// external
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Label, FormGroup, Col } from "reactstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";

// internal
import { InformationBadge, InformationBadgeProps } from "../information-badge/information-badge";
import SuperscriptDisplay from "../../components/superscript/superscript-display";
import { useCustomForm } from "../custom-form/context/context";
import LabelError from "../label-error/label-error";
import { RequiredLabel } from "./required-label";
import { sortObj } from "../../utils/sortObj";
import { HideButton } from "./hide-button";

// styles
import "./custom-modal-select.styles.scss";

export const customStyles = {
    placeholder: (css) => {
        return {
            ...css,
            color: "#a4a6a8",
        };
    },
    indicatorsContainer: (css, state) => {
        return {
            ...css,
            div: {
                marginTop: "-2px",
                padding: state.selectProps.notLabeled ? "0px" : "8px",
            },
        };
    },
    valueContainer: (css, state) => {
        return state.selectProps.notLabeled
            ? {
                  ...css,
                  maxHeight: "27px",
                  paddingLeft: "17px",
              }
            : {
                  ...css,
                  paddingLeft: "17px",
              };
    },
    singleValue: (css, state) => {
        return { ...css, color: "unset" };
    },
    control: (css, state) => {
        let newCSS = {
            ...css,
            cursor: "pointer",
        };

        if (state.selectProps.notLabeled) {
            newCSS = {
                ...newCSS,
                boxShadow: "none",
                minHeight: "27px",
                height: "27px",
            };
        }

        return newCSS;
    },
    option: (css, state) => {
        let bgc = "unset";

        if (state.isSelected) {
            bgc = "#725ec3";
        }

        return {
            ...css,
            backgroundColor: bgc,
            paddingTop: "3px",
            paddingBottom: "3px",
        };
    },
    menuPortal: (css) => {
        return { ...css, zIndex: 1050 };
    },

    menu: (css) => {
        return { ...css, zIndex: 2 };
    },
};

interface ICustomModalSelect {
    t: Function;
    handleChange?: Function;
    value?: any;
    options: any;
    onFocus?: Function;
    labelName?: string;
    autofocus?: boolean;
    menuIsOpen?: boolean;
    name?: any;
    labelSm?: string | number;
    colSm?: string | number;
    superscript?: boolean;
    defaultValue?: any;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    hideLabel?: boolean;
    portalTarget?: HTMLElement;
    isClearable?: boolean;
    labelClassName?: string;
    onOpen?: Function;
    children?: any;
    errors?: any;
    informationBadge?: boolean;
    informationBadgeProps?: InformationBadgeProps;
    translateOptions?: boolean;
    sortBy?: { key: string; ascending?: boolean }; // sort by key in options, default descending
    required?: boolean;
    innerRef?: any;
    cyId?: string;
}

const CustomModalSelect = (props: ICustomModalSelect) => {
    const {
        t,
        labelName,
        handleChange = () => {},
        onFocus,
        autofocus,
        menuIsOpen,
        name,
        labelSm = 3,
        colSm,
        superscript,
        defaultValue,
        className,
        disabled,
        placeholder,
        hideLabel,
        portalTarget,
        isClearable = true,
        labelClassName,
        onOpen,
        children,
        sortBy,
        informationBadge,
        translateOptions,
        informationBadgeProps,
        required = false,
        innerRef,
    } = props;
    let { value, options, errors } = props;

    const { isHideMode } = useCustomForm();

    const [isHidden, setIsHidden] = useState<boolean>(false);

    const customFilter = (option, inputValue) => {
        let compared = option.data?.stringValue == null ? option.label : option.data.stringValue;
        return compared.toLowerCase().includes(inputValue.toLowerCase());
    };

    useEffect(() => {
        const inputRefElement = document.getElementById("select-input-" + name);
        innerRef && (innerRef.current = inputRefElement);
        if (autofocus && inputRefElement) inputRefElement.focus();
    }, []);

    options = sortBy ? options?.sort((a, b) => sortObj(a, b, sortBy.key, sortBy.ascending)) : options;

    if (!options || Object.keys(options).length == 0) {
        options = [];
    }

    if (superscript) {
        options = options.map((o, i) => ({
            label: (
                <div>
                    <SuperscriptDisplay key={`opt-${i}`} value={o.label} />
                </div>
            ),
            stringValue: o.label.replace(/[↑↓→←]/gi, ""), // '↑', '↓', '→', '←'
            value: o.value,
        }));
    }

    const valueWrapper = (v) => {
        // docelowo wysylajmy samo value i robmy finda na value
        // roznica: taka ze w apkach jak wysylamy propsa value to i tak robimy na nim najpierw finda
        // zamiast tego byloby szybciej wysylac samo value, a wrapped bedzie za nas szukal .find-em
        if (v === null || v === undefined) {
            return v;
        }
        if (v?.label && translateOptions) v.label = t(v.label);
        if (!superscript) return v;
        return options.find((o) => o.value == v.value);
    };

    if (value && errors && errors[0] == "This field is required." && errors.length == 1) {
        errors = undefined;
    }
    if (!superscript && translateOptions) options = options?.map((option) => ({ label: t(option.label), value: option.value }));

    if (isHidden && !isHideMode) return <></>;

    return (
        <Fragment>
            {labelName ? (
                <FormGroup row>
                    {hideLabel ? (
                        ""
                    ) : (
                        <Label className={labelClassName} sm={labelSm}>
                            {t(labelName)}:{required && <RequiredLabel />}
                        </Label>
                    )}
                    {informationBadge && <InformationBadge {...informationBadgeProps} selector={name} />}
                    <Col sm={colSm}>
                        {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                        <Select
                            isClearable={isClearable}
                            className={`custom-modal-select ${disabled ? "custom-select-disabled" : ""} ${className || ""} ${
                                errors ? "custom-gt-select is-invalid" : ""
                            }`}
                            options={options}
                            styles={customStyles}
                            onChange={(e, f) => handleChange({ target: { name: f.name, value: e?.value, type: "select-box" } })}
                            onFocus={onFocus}
                            menuIsOpen={menuIsOpen}
                            value={valueWrapper(value)}
                            defaultValue={options.find((o) => o.value == defaultValue)}
                            {...(defaultValue === null || defaultValue === undefined
                                ? {}
                                : { defaultValue: options.find((o) => o.value == defaultValue) })}
                            name={name}
                            placeholder={placeholder ? `${t(placeholder)}...` : `${t(labelName)}...`}
                            filterOption={customFilter}
                            isDisabled={disabled || false}
                            menuPortalTarget={portalTarget || undefined}
                            onMenuOpen={onOpen}
                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                            inputId={"select-input-" + name}
                        />
                        {errors && <LabelError id={name} error={errors} />}
                    </Col>
                    {children}
                </FormGroup>
            ) : (
                <Fragment>
                    {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                    {required && <RequiredLabel />}
                    <Select
                        isClearable={isClearable}
                        notLabeled
                        className={`custom-modal-select ${disabled ? "custom-select-disabled" : ""} ${className || ""} ${
                            errors ? "custom-gt-select is-invalid" : ""
                        }`}
                        options={options}
                        styles={customStyles}
                        onChange={(e, f) =>
                            handleChange({ target: { name: f.name, value: e?.value === undefined ? null : e?.value, type: "select-box" } })
                        }
                        onFocus={onFocus}
                        autoFocus={autofocus}
                        menuIsOpen={menuIsOpen}
                        value={valueWrapper(value)}
                        //defaultValue = { options.find( o => o.value == defaultValue) }
                        {...(defaultValue === null || defaultValue === undefined
                            ? {}
                            : { defaultValue: options.find((o) => o.value == defaultValue) })}
                        filterOption={customFilter}
                        menuPortalTarget={document.body}
                        name={name}
                        isDisabled={disabled || false}
                        placeholder={placeholder ? `${t(placeholder)}...` : ""}
                        onMenuOpen={onOpen}
                        noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                        inputId={"select-input-" + name}
                    />
                    {errors && <LabelError id={name} error={errors} />}
                </Fragment>
            )}
        </Fragment>
    );
};

export default withTranslation()(CustomModalSelect);
