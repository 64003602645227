import React, { Fragment } from "react";

import { useFetchData } from "hooks/useFetchData";

import * as GT from "components";

//? autofocus props comes from index == 0, if the first element in the form is rendered it should be focused or controlled by field prop

export const SelectWithOptions = (props) => {
    const { field, formData, errors, onChange, autofocus } = props;

    const { data } = useFetchData({ url: field.url, triggerValues: [...(field.triggerValues || [])], params: field.params });

    const options = typeof field.options != "function" ? field.options || data : field.options(data);

    const isClearable = (() => {
        if (field.required === true) return false;
        else return field.isClearable;
    })();

    return (
        <>
            {
                {
                    select: (
                        <GT.CustomSelect
                            labelName={field.label}
                            name={field.name}
                            options={options}
                            value={options?.find((option) => (field?.value || formData?.[field.name]) == option.value) || ""}
                            handleChange={field.onChange || onChange}
                            disabled={field.disabled}
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    multiSelect: (
                        <GT.PrimeMultiSelect
                            rowClassName="mb-20"
                            label={field.label}
                            name={field.name}
                            options={options}
                            value={field?.value || formData?.[field.name]}
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            onChange={(e) =>
                                (field.onChange || onChange)({ ...e, target: { ...e.target, value: e.value === null ? [] : e.value } })
                            }
                            disabled={field.disabled}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    creatableSelect: (
                        <GT.CustomCreatableSelect
                            labelName={field.label}
                            name={field.name}
                            options={options}
                            value={formData?.[field.name] || ""}
                            handleChange={field.onChange || onChange}
                            disabled={field.disabled}
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    multiCreatableSelect: (
                        <GT.CustomMultiCreatableSelect
                            labelName={field.label}
                            name={field.name}
                            options={options}
                            value={options?.find((option) => (field?.value || formData?.[field.name]) == option.value)}
                            handleChange={field.onChange || onChange}
                            disabled={field.disabled}
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    primeSelect: (
                        <GT.PrimeSelect
                            rowClassName="mb-20"
                            label={field.label}
                            name={field.name}
                            options={options}
                            value={field?.value || formData?.[field.name]}
                            onChange={field.onChange || onChange}
                            disabled={field.disabled}
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                }[field.fieldType]
            }
        </>
    );
};
