// internal
import React from "react";
import { withTranslation } from "react-i18next";
import { Col, FormGroup, Label } from "reactstrap";
import Icon from "../icon";
import { debounce } from "lodash";
import axios from "axios";

// external
import ZWSB from "../../utils/zwsb";
import { ShowSimilarityOptions } from "../show-similarity-options/show-similarity-options";

// style
import "./superscript.scss";
import { RequiredLabel } from "components/custom-modal-elements/required-label";

class SuperscriptInput extends React.Component<any, any> {
    // needed to control the superscript div
    myRef = React.createRef() as any;

    constructor(props) {
        super(props);
        this.state = { openControls: false, helperText: null, isFocus: false, value: "" };
    }

    componentDidMount() {
        // pretty display if values given
        if (this.props.value) this.myRef.current.innerHTML = this.parseSuperscript(this.props.value, "display");
        this.props.autofocus &&
            setTimeout(() => {
                this.myRef?.current?.focus();
            }, 1);
    }

    componentDidUpdate(): void {
        if (this.props.value != this.parseSuperscript(this.myRef.current.innerHTML.replace(/&nbsp;/g, " "), "shorten")) {
            const res = this.parseSuperscript(this.props.value || "", "display");
            this.myRef.current.innerHTML = res;
        }
    }

    // helper text for better UX
    changeHelperText = (text) => this.setState({ helperText: text });

    // show side superscript controls
    showControls = () => this.setState({ openControls: true });

    // hide side superscript controls
    hideControls = () => {
        // blur triggers on button presses, but we dont want to close the controls
        if (this.btnTrigger == true) return;

        this.setState(
            {
                openControls: false,
            },
            () => {
                this.ununglify();
            }
        );
    };

    // parse to pretty html or shorter string
    parseSuperscript = (text, mode) => {
        let r = ["↑", "↓", "→", "←"];
        let rm = ["<sub>", "</sub>", "<sup>", "</sup>"];

        switch (mode) {
            case "shorten":
                [r, rm] = [rm, r];
                break;

            case "display":
                // ...
                break;
        }

        r.forEach((e, i) => (text = text.replaceAll(e, rm[i])));
        return this.stripTags(text);
    };

    // strip any html tags that aren't relevant to this component (sub & sup)
    stripTags = (input) => {
        const allowed = ("<sub><sup>".toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join("");
        const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

        return input.replaceAll(ZWSB, "").replace(tags, ($0, $1) => {
            return allowed.indexOf(`<${$1.toLowerCase()}>`) > -1 ? $0 : "";
        });
    };

    // prevent new line on Enter press
    preventEnterKey = (event) => {
        const key = event.keyCode || event.charCode;

        if (key == 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    // create fake event + target for parent components
    parseEventForHandler = (e) => {
        const parsedVal = this.parseSuperscript(e.target.innerHTML, "shorten")
            .replaceAll("&lt;", "<")
            .replaceAll("&gt;", ">")
            .replaceAll("&nbsp;", " ")
            .replaceAll("<br>", "");

        return {
            target: {
                name: e.target.id,
                value: parsedVal,
            },
        };
    };

    // fix weird encoding on '<', '>' and white space
    ununglify = () => {
        const html = this.myRef.current.innerHTML;
        const fixedHTML = html.replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("&nbsp;", " ").replaceAll("<br>", "");

        this.myRef.current.innerHTML = this.parseSuperscript(fixedHTML, "display");
    };

    // insert HTML tag via execCommand
    addTag = (cmd) => {
        this.myRef.current.focus();

        if (cmd) {
            document.execCommand(cmd);
        } else {
            document.execCommand("insertHTML", false, `<div>${document.getSelection()}${ZWSB}</div>`);
        }

        this.btnTrigger = false;

        // older version using Selection objects
        // leave this code for now...

        // let sel = window.getSelection() as Selection
        // let range = sel?.getRangeAt(0) as Range
        // let extracted = range.extractContents() as any
        // let extractedHTML = ''
        // for ( let node of extracted.childNodes) {
        //     extractedHTML += (node.outerHTML || node.textContent || '')
        // }
        // let oldHTML = this.myRef.current.innerHTML
        // this.myRef.current.innerHTML = oldHTML + `<${tag}>${extractedHTML}</${tag}>`
        // this.btnTrigger = false
    };

    handleChangeContent = (e) => {
        let r = ["↑", "↓", "→", "←"];

        let value = this.parseSuperscript(e.target.innerHTML, "shorten")
            .replaceAll("&lt;", "<")
            .replaceAll("&gt;", ">")
            .replaceAll("&nbsp;", " ")
            .replaceAll("<br>", "");

        r.forEach((e) => (value = value.replaceAll(e, "")));

        if (value.length < 3) this.setState({ similarOpts: [] });
        else this.handleGetSimilarities(value);

        this.props.handleChange(this.parseEventForHandler(e));
    };

    handleGetSimilarities = debounce(async (value: string) => {
        try {
            const resp = await axios.get(
                this.props.duplicateMode?.pathname + `?search_field=${this.props.duplicateMode.field}__icontains&search=${value}`
            );
            const opts = resp.data.data.map((x) => x[this.props.duplicateMode.field]);

            this.setState({ similarOpts: opts });
        } catch (err) {
            console.error(err);
        }
    }, 700);

    handleFocus = () => {
        this.setState({ isFocus: true });
        this.showControls();
    };

    handleBlur = () => {
        this.setState({ isFocus: false });
        this.hideControls();
    };

    btnTrigger = false;

    render() {
        const { t, errors, handleChange, labelText, name, labelSm, colSm, placeholder, className, isInput } = this.props;

        return (
            <>
                {isInput ? (
                    <FormGroup row className={className}>
                        <Label for={name} sm={labelSm || 3}>
                            {t(labelText)}:{this.props.required && <RequiredLabel />}
                        </Label>
                        <Col sm={colSm}>
                            <div
                                ref={this.myRef}
                                id={name}
                                suppressContentEditableWarning={true}
                                contentEditable={isInput == null ? true : isInput}
                                className={`${this.state.openControls ? "w100-r110" : ""} inline-block superscript ${
                                    errors ? "is-invalid" : ""
                                }`}
                                onInput={(e) => this.handleChangeContent(e)}
                                onKeyDown={this.preventEnterKey}
                                onPaste={(e) => setTimeout(() => this.ununglify(), 0)}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                title={this.myRef.current?.innerHTML == "" ? t(labelText || placeholder) + "..." : ""} // placeholder
                            ></div>

                            {this.state.openControls ? (
                                <>
                                    <div className="inline-block superscript-controls ml-20">
                                        <div
                                            className="btn opacity-anim btn-custom-round inline-block mr-20 mt-10"
                                            onMouseEnter={() => this.changeHelperText(t("Subscript"))}
                                            onMouseLeave={() => this.changeHelperText(null)}
                                            onMouseDown={() => (this.btnTrigger = true)}
                                            onMouseUp={() => this.addTag("subscript")}
                                        >
                                            <Icon name={["fas", "subscript"]} />
                                        </div>
                                        <div
                                            className="btn opacity-anim btn-custom-round inline-block mt-10 mr-20"
                                            onMouseEnter={() => this.changeHelperText(t("Superscript"))}
                                            onMouseLeave={() => this.changeHelperText(null)}
                                            onMouseDown={() => (this.btnTrigger = true)}
                                            onMouseUp={() => this.addTag("superscript")}
                                        >
                                            <Icon name={["fas", "superscript"]} />
                                        </div>
                                        <div
                                            className="btn opacity-anim btn-custom-round inline-block mt-10"
                                            onMouseEnter={() => this.changeHelperText(t("Default"))}
                                            onMouseLeave={() => this.changeHelperText(null)}
                                            onMouseDown={() => (this.btnTrigger = true)}
                                            onMouseUp={() => this.addTag(null)}
                                        >
                                            <Icon name={["fas", "remove-format"]} />
                                        </div>
                                    </div>
                                    {this.state.helperText ? <div className="superscript-help">{this.state.helperText}</div> : ""}
                                </>
                            ) : (
                                ""
                            )}

                            {!!this.state.similarOpts && this.state.isFocus && (
                                <ShowSimilarityOptions values={this.state.similarOpts} inputRef={this.myRef} />
                            )}

                            {errors && <Label className="invalid-feedback">{t(errors)}</Label>}
                        </Col>
                    </FormGroup>
                ) : (
                    // you should probably use a more lightweight superscript-display component instead
                    <div ref={this.myRef}></div>
                )}
            </>
        );
    }
}

export default withTranslation()(SuperscriptInput);
