import React, { createContext, useContext, useState } from "react";

import { releaseOrdersTabs, ReleaseOrdersTabValuesType } from "../utils/release-orders-Tabs";
import { orderModes, OrderModesTypes } from "../../utils/modes";
import { useFormData } from "../../../../hooks/useFormData";
import { IOrder, ISample } from "../../interface";
import { useGetData } from "../../../../hooks/useGetData";
import { IData } from "hooks/useGetData";

export const OrderReleaseOrdersContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.releaseOrders;
export const OrderReleaseOrdersContextProvider = ({ children }) => {
    const [selectedOrders, setSelectedOrders] = useState<IOrder[]>([]);
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);

    const [selectedTab, setSelectedTab] = useState<ReleaseOrdersTabValuesType>(releaseOrdersTabs.samples);

    const [refreshOrdersData, setRefreshOrdersData] = useState<Function | undefined>(undefined);

    const sampleObj = useGetData<ISample>({
        url: selectedOrders.length > 0 ? `/samples` : undefined,
        triggerValues: [selectedOrders],
        additionalParams: `&orderId=${selectedOrders[0]?.id}`,
        onUnmount: () => setSelectedSamples([]),
    });

    const { formData: allReportTypes } = useFormData<string[], any>({
        url: "/bs/testPlans/test_plan_report_types",
        mapFormData: (data) => data.reportTypes.map((rt) => rt.value),
    });

    return (
        <OrderReleaseOrdersContext.Provider
            value={{
                selectedOrders,
                setSelectedOrders,
                selectedSamples,
                setSelectedSamples,
                allReportTypes,
                refreshOrdersData,
                setRefreshOrdersData,
                selectedTab,
                setSelectedTab,
                mode,
                sampleObj,
            }}
        >
            {children}
        </OrderReleaseOrdersContext.Provider>
    );
};

export const useOrderReleaseOrders = (): IUseOrderReleaseOrders => {
    const {
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        allReportTypes,
        refreshOrdersData,
        setRefreshOrdersData,
        selectedTab,
        setSelectedTab,
        mode,
        sampleObj,
    } = useContext(OrderReleaseOrdersContext);

    return {
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        allReportTypes,
        refreshOrdersData,
        setRefreshOrdersData,
        selectedTab,
        setSelectedTab,
        mode,
        sampleObj,
    };
};

interface IUseOrderReleaseOrders {
    selectedOrders: IOrder[];
    setSelectedOrders: (arg: IOrder[]) => void;
    selectedSamples: ISample[];
    setSelectedSamples: (arg: ISample[]) => void;
    allReportTypes?: string[];
    refreshOrdersData: Function;
    setRefreshOrdersData: Function;
    selectedTab: ReleaseOrdersTabValuesType;
    setSelectedTab: (arg: ReleaseOrdersTabValuesType) => void;
    mode: OrderModesTypes;
    sampleObj: {
        data: IData<ISample>;
        setData: React.Dispatch<React.SetStateAction<IData<ISample>>>;
        refreshData: Function;
        handleReload: (params: any, refresh: any) => void;
    };
}
