import { onClientSideEdit, onSubmitEdit } from "./onSubmitEdit";
import { tableUrls } from "../utils/table-urls";

import * as interfaces from "../interfaces";

interface IApplySubmit<T> {
    submitEvent: interfaces.EditSubmitEventType;
    firstPartEditUrl: string;
    customHandleEditSubmit: any;
    extraEditParams: { [key: string]: any };
    params: interfaces.IPrimeParams;
    hideSuccessToast: boolean;
    isAdditionalColumn?: boolean;
    meta?: interfaces.IMeta | null | undefined;
    idSelector: string;
    handleCustomResponse: interfaces.HandleCustomResponse | undefined;
    setData?: interfaces.TableSetData<T>;
    onEdit?: interfaces.OnEditType<T>;
    onReload?: (params, refresh) => void;
}

export const applySubmit = async <T extends interfaces.IBaseRecord>({
    submitEvent,
    firstPartEditUrl,
    customHandleEditSubmit,
    extraEditParams,
    params,
    hideSuccessToast,
    isAdditionalColumn,
    meta,
    idSelector,
    handleCustomResponse,
    setData,
    onEdit,
    onReload,
}: IApplySubmit<T>) => {
    const {
        row,
        e,
        secondPartEditUrl = "",
        extraColumnEditParams,
        refresh,
        clientSideEdit,
        extraParametersToSet,
        editUrl = undefined,
        urlInstanceId = undefined,
        // onPress,
        reqParams,
    } = submitEvent;

    const url = editUrl ? editUrl : firstPartEditUrl + secondPartEditUrl;

    if (customHandleEditSubmit) {
        const urls = { tableEditUrl: firstPartEditUrl, inputEditUrl: secondPartEditUrl, editUniqueUrl: editUrl };

        await customHandleEditSubmit(e, row, urls, extraColumnEditParams);
    }

    if ((!clientSideEdit && !!url) || isAdditionalColumn) {
        const extraParams = { ...extraColumnEditParams, ...extraEditParams };
        const { filters, multiSortMeta } = params;

        const response = await onSubmitEdit<T>({
            rowData: row,
            e,
            url: isAdditionalColumn ? tableUrls.additionalColumnCellEdit : url,
            params: isAdditionalColumn ? { modelLabel: meta?.ModelInfo?.appModelLabel } : extraParams,
            refresh: isAdditionalColumn ? true : refresh,
            hideSuccessToast,
            urlInstanceId,
            idSelector,
            handleCustomResponse,
            setData,
            extraParametersToSet,
            onEdit: isAdditionalColumn ? undefined : onEdit,
            reqParams,
        });

        //! commented when table in application my result sheets -> analytes refreshed every result change
        // if ((refresh || filters || multiSortMeta) && onReload && response?.status?.toString()[0] == "2") {
        //     onReload(undefined, true);
        // }

        return response;
    } else {
        onClientSideEdit<T>(row, e, idSelector, setData, onEdit);
    }
};
