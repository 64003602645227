import { getNestedObjValue } from "../helpers/primeHelpers";
import { Checkbox } from "primereact/checkbox";
import React, { SyntheticEvent, useRef } from "react";

import { IEditCheckboxInputProps } from "../interfaces/edit-interfaces";
import { sweetConfirm } from "../../sweet-alert/sweetConfirm";
import { ReqParamsHOC } from "../helpers/req-params-hoc";
import { memoEditInput } from "./memoEditInput";

// table edit component, you send it in the column as editBody prop
// editBody: (inputData: IPrimeEditData<ProperInterface>) => (<EditCheckboxInput inputData={inputData} />),
const EditCheckboxInputBase = (props: IEditCheckboxInputProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        value = false,
        className = "",
        style = {},
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <div className="checkbox-dummy-container">
            <Checkbox
                checked={value ? value : !!getNestedObjValue(row, fieldName.split("."))}
                disabled
                id="input-placeholder"
                className={`prime-table-checkbox ${className}`}
                key={`prime-input-checkbox-${fieldName}-${row.id}`}
                style={style}
            />
        </div>
    );
};

export const EditCheckboxInput = memoEditInput(EditCheckboxInputBase);

export const EnabledInput = (props: IEditCheckboxInputProps) => {
    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        secondPartEditUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        extraParametersToSet = undefined,
        //unique
        ifTrue = true,
        ifFalse = false,
        children,
        sweetConfirmMessage,
    } = props;

    const { row, fieldName, handleEditSubmit, permCode = "" } = props.inputData;

    const formRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const _value = value || !!row[fieldName] || "";
    const _name = name || fieldName;

    const handleChange = async (e) => {
        if (sweetConfirmMessage && !(await sweetConfirm("Warning", sweetConfirmMessage))) return;
        if (!permCode) handleSubmit(e, undefined);
        else {
            formRef.current && formRef.current.click();
        }
    };

    const handleSubmit = async (e: SyntheticEvent, reqParams?: IReqParams) => {
        const _e = { target: { name: _name, value: !inputRef?.current?.checked ? ifTrue : ifFalse } };
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;

        await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
            editUrl,
            reqParams: reqParams,
        });
    };

    return (
        <ReqParamsHOC onSubmit={handleSubmit} permCode={permCode} formRef={formRef}>
            <Checkbox
                name={_name}
                onChange={handleChange}
                checked={_value}
                id="prime-input-checkbox"
                className={`prime-table-checkbox ${className}`}
                key={`prime-table-checkbox-${fieldName}-${row.id}`}
                style={style}
                inputRef={inputRef}
            />
            {children && children}
        </ReqParamsHOC>
    );
};
