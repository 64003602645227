import React, { CSSProperties, FocusEventHandler, Fragment, KeyboardEventHandler, useEffect, useRef, useState } from "react";

import { Label, FormGroup, Col, Input, CustomInput, Tooltip } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import { withTranslation } from "react-i18next";
import axios from "axios";

import { InformationBadge, InformationBadgeProps } from "../information-badge/information-badge";
import { ShowSimilarityOptions } from "../show-similarity-options/show-similarity-options";
import { useCustomForm } from "../custom-form/context/context";
import { useDebounce } from "../../hooks/useDebounce";
import LabelError from "../label-error/label-error";
import { HideButton } from "./hide-button";

import "./custom-modal-input.scss";
import { RequiredLabel } from "./required-label";

type AutoCompleteType = "off" | "on" | "new-password";
interface ICustomModalInput {
    t: Function;
    handleChange?: any;
    value?: any;
    forLabel?: string | undefined;
    labelText?: string;
    type?: InputType;
    name?: any;
    labelSm?: any;
    colSm?: any;
    placeholder?: string;
    autoComplete?: AutoCompleteType;
    className?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
    noLabel?: boolean;
    tooltipText?: any;
    handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    readOnly?: any;
    onMouseDown?: Function;
    defaultValue?: any;
    disabled?: boolean;
    checked?: boolean;
    min?: number | string;
    step?: string | number;
    max?: number | string;
    style?: CSSProperties;
    errors?: any;
    firstInput?: boolean;
    id?: string | number;
    children?: any;
    mask?: string;
    tag?: any;
    propRef?: any;
    duplicateMode?: { pathname: string; field: string };
    propInnerRef?: any;
    autofocus?: boolean;
    triggerAutofocus?: any;
    informationBadge?: boolean;
    informationBadgeProps?: InformationBadgeProps;
    required?: boolean;
    pattern?: string;
    cyId?: string;
}

const CustomModalInput = (props: ICustomModalInput) => {
    const {
        t,
        handleChange,
        forLabel = "",
        labelText,
        type = "text",
        name,
        labelSm = 3,
        colSm,
        placeholder,
        autoComplete = "off",
        value,
        className = "modal-input",
        onBlur = undefined,
        onFocus = undefined,
        onKeyPress = undefined,
        noLabel = false,
        tooltipText,
        handleKeyDown = undefined,
        readOnly = undefined,
        onMouseDown = undefined,
        defaultValue = undefined,
        disabled = false,
        checked = false,
        min = undefined,
        step = "any",
        max = undefined,
        style,
        firstInput = false,
        children,
        mask = undefined,
        tag = undefined,
        duplicateMode = { pathname: "", field: "" },
        autofocus = false,
        triggerAutofocus = undefined, // additional props to set autofocus when input renders not displayed
        informationBadge,
        informationBadgeProps,
        required = false,
        pattern,
    } = props;

    let { errors, propRef } = props;

    const { isHideMode } = useCustomForm();

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [similarOpts, setSimilarOpts] = useState<string[]>([]);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const inputRef = useRef(null) as any;
    const debounceValue = useDebounce(value, 700);

    const isSimilarity = !!duplicateMode.pathname && !!duplicateMode.field;

    useEffect(() => {
        if (isSimilarity && value.length > 2) {
            autocompleteSearch();
        }
    }, [debounceValue]);

    useEffect(() => {
        autofocus &&
            setTimeout(() => {
                inputRef?.current?.focus();
            }, 1);
    }, [triggerAutofocus]);

    const handleFocus = (e) => {
        onFocus && onFocus(e);
        setIsFocus(true);
    };

    const handleBlur = (e) => {
        onBlur && onBlur(e);
        setIsFocus(false);
    };

    const autocompleteSearch = async () => {
        try {
            const resp = await axios.get(duplicateMode?.pathname + `?search_field=${duplicateMode.field}__icontains&search=${value}`);
            const options = resp.data.data.map((x) => x[duplicateMode.field]);
            setSimilarOpts(options);
        } catch (err) {
            console.error(err);
        }
    };

    const translateErrorString = (errorStrings: Array<string>) => {
        let result = [] as any;
        if (typeof errorStrings == "string") {
            return t(errorStrings);
        }

        const regexList = [
            /^Ensure this field has no more than (\d+) characters.$/,
            /^Ensure that there are no more than (\d+) digits in total.$/,
            /^Ensure that there are no more than (\d+) digits before the decimal point.$/,
            /^Ensure that there are no more than (\d+) decimal places.$/,
            /Range is overlaping with (.*) and (.*)/,
        ];
        const translationList = [
            "Ensure this field has no more than {{count}} characters",
            "Ensure that there are no more than {{count}} digits in total",
            "Ensure that there are no more than {{count}} digits before the decimal point",
            "Ensure that there are no more than {{count}} decimal places",
            "Range is overlaping with {{substring}} and {{substring_2}}",
        ];

        let errorMatch;
        for (let errorString of errorStrings) {
            for (let i = 0; i < regexList.length; i++) {
                errorMatch = errorString.match(regexList[i]);
                if (errorMatch) {
                    result.push(
                        t(translationList[i], { count: Number(errorMatch[1]), substring: errorMatch[1], substring_2: errorMatch?.[2] })
                    );
                    break;
                }
            }

            if (!errorMatch) result.push(t(errorString));
        }
        return result.join(". ");
    };

    const handleChangeInput = (e) => {
        if (e.target.value.length < 3) setSimilarOpts([]);
        handleChange && handleChange(e);
    };

    if (value && errors && errors[0] == "This field is required." && errors.length == 1) {
        errors = undefined;
    }

    if (isHidden && !isHideMode) return <></>;

    return (
        <Fragment>
            {firstInput ? (
                <FormGroup row className={className}>
                    {informationBadge && <InformationBadge {...informationBadgeProps} selector={name} />}
                    <Col sm={colSm}>
                        {/* Checkbox is styled properly only in CustomInput */}
                        {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                        {type === "checkbox" ? (
                            <CustomInput
                                autoComplete={autoComplete}
                                name={name}
                                onChange={handleChange}
                                className={errors ? "is-invalid" : "w-100"}
                                type={type}
                                id={forLabel}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                onKeyPress={onKeyPress}
                                onKeyDown={handleKeyDown}
                                readOnly={readOnly}
                                onMouseDown={onMouseDown || readOnly ? (e) => e.preventDefault() : undefined}
                                defaultValue={defaultValue}
                                disabled={disabled}
                                checked={checked}
                                ref={propRef}
                            />
                        ) : (
                            <Input
                                mask={mask}
                                tag={tag}
                                autoComplete={autoComplete}
                                name={name}
                                onChange={handleChangeInput}
                                className={errors ? "is-invalid" : ""}
                                type={type}
                                id={forLabel}
                                placeholder={(t(placeholder) || t(labelText)) + "..."}
                                value={value}
                                step="any"
                                onBlur={onBlur}
                                onFocus={onFocus}
                                onKeyPress={onKeyPress}
                                onKeyDown={handleKeyDown}
                                readOnly={readOnly}
                                onMouseDown={onMouseDown || readOnly ? (e) => e.preventDefault() : undefined}
                                defaultValue={defaultValue}
                                disabled={disabled}
                                min={min}
                                max={max}
                                style={style}
                                ref={propRef}
                                innerRef={inputRef}
                                pattern={pattern}
                            />
                        )}
                        {errors && <LabelError id={forLabel} error={translateErrorString(errors)} />}
                        {tooltipText && forLabel && !errors && (
                            <Tooltip target={forLabel} isOpen={tooltipOpen} toggle={() => setTooltipOpen((prev) => !prev)}>
                                {tooltipText}
                            </Tooltip>
                        )}
                    </Col>
                    {noLabel ? null : (
                        <Label for={forLabel} sm={labelSm || 3}>
                            {t(labelText)}
                            {required && <RequiredLabel />}
                        </Label>
                    )}
                </FormGroup>
            ) : (
                <FormGroup row className={className}>
                    {noLabel ? null : (
                        <Label for={forLabel} sm={labelSm || 3}>
                            {t(labelText)}:{required && <RequiredLabel />}
                        </Label>
                    )}
                    {informationBadge && <InformationBadge {...informationBadgeProps} selector={name} />}
                    <Col sm={colSm}>
                        {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                        {/* Checkbox is styled properly only in CustomInput */}
                        {type === "checkbox" ? (
                            <CustomInput
                                autoComplete={autoComplete}
                                name={name}
                                onChange={handleChange}
                                className={errors ? "is-invalid" : "w-100"}
                                type={type}
                                id={forLabel}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                onKeyPress={onKeyPress}
                                onKeyDown={handleKeyDown}
                                readOnly={readOnly}
                                onMouseDown={onMouseDown || readOnly ? (e) => e.preventDefault() : undefined}
                                defaultValue={defaultValue}
                                disabled={disabled}
                                checked={checked}
                                ref={propRef}
                            />
                        ) : (
                            <Input
                                mask={mask}
                                tag={tag}
                                autoComplete={autoComplete}
                                name={name}
                                onChange={handleChangeInput}
                                className={errors ? "is-invalid" : ""}
                                type={type}
                                id={forLabel}
                                placeholder={(t(placeholder) || t(labelText)) + "..."}
                                value={value}
                                step="any"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onKeyPress={onKeyPress}
                                onKeyDown={handleKeyDown}
                                readOnly={readOnly}
                                onMouseDown={onMouseDown || readOnly ? (e) => e.preventDefault() : undefined}
                                defaultValue={defaultValue}
                                disabled={disabled}
                                min={min}
                                max={max}
                                style={style}
                                ref={propRef}
                                innerRef={inputRef}
                                pattern={pattern}
                            />
                        )}
                        {errors && <LabelError id={forLabel} error={translateErrorString(errors)} />}
                        {tooltipText && forLabel && !errors && (
                            <Tooltip target={forLabel} isOpen={tooltipOpen} toggle={() => setTooltipOpen((prev) => !prev)}>
                                {tooltipText}
                            </Tooltip>
                        )}
                        {!!similarOpts && isFocus && <ShowSimilarityOptions values={similarOpts} inputRef={inputRef} />}
                    </Col>
                    {children}
                </FormGroup>
            )}
        </Fragment>
    );
};

export default withTranslation()(CustomModalInput);
